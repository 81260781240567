<template>
  <div class="declare" style="position: relative;">
    <vipPermission v-if="vip === '普通用户'" :vipPermissionVisible="true" :type="4"></vipPermission>
    <div style="padding-top: 10px;">
      <div class="title">{{ title }}</div>
      <el-divider></el-divider>
      <div class="subtitle">
        <img
          src="../../../../assets/pc/images/member/history_record.png"
          alt=""
        />
        <span @click="handleHistory">{{ history_title }}</span>
      </div>
      <div class="timeline" v-if="list.length>0">
        <div v-for="(item, index) in list" :key="index">
          <div>{{ item.year }}年</div>
          <div class="timeline-item">
            <div v-for="(v, i) in item.yearList" :key="i" class="month_item">
              <div class="item-moth">{{ v.month }}月</div>
              <div
                class="item-tail"
                v-show="i < item.yearList.length - 1"
              ></div>
              <div class="item-node"></div>
              <el-card
                v-for="(m, n) in v.monthList"
                :key="n"
                class="box-card"
                :style="n == v.monthList.length - 1 ? '' : 'margin-bottom:20px'"
              >
                <div class="title">{{ m.subtitle }}</div>
                <div>
                  <span>{{ m.fwDepartment }}</span>
                  <span>预计发文日期：{{ m.expectDispatchDate.split('/').join('-') }}</span>
                </div>
                <el-divider></el-divider>
                <div>
                  <span>负责人：{{ m.responsibleMan }}</span>
                  <span>申报服务商：{{ m.declareSupplier }}</span>
                  <span>配套服务商：{{ m.serviceSupplier }}</span>
                  <span @click="edit(m)">编辑</span>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="none-data" style="position: relative;">
        <img src="@/assets/pc/images/personal/none.png" alt="">
        <span style="font-size: 14px; color: #B0B0B0">暂无内容</span>
      </div>
      <div>
        <el-dialog
          title="编辑"
          :visible.sync="dialogVisible"
          width="30%"
          append-to-body
        >
          <div style="margin: 20px"></div>
          <el-form
            label-position="top"
            label-width="80px"
            :model="formLabelAlign"
          >
            <el-form-item label="负责人">
              <el-input
                v-model="formLabelAlign.responsibleMan"
                placeholder="请输入负责人名称"
                maxlength="9"
              ></el-input>
            </el-form-item>
            <el-form-item label="申报服务商">
              <el-input
                v-model="formLabelAlign.declareSupplier"
                placeholder="申报服务商名称"
                maxlength="9"
              ></el-input>
            </el-form-item>
            <el-form-item label="配套服务商">
              <el-input
                v-model="formLabelAlign.serviceSupplier"
                placeholder="请输入配套服务商名称"
                maxlength="9"
              ></el-input>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button type="primary" @click="submitForm" size="medium"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-dialog>
      </div>
    </div>
    <!-- <open v-else @upgrade="upgrade"></open> -->
  </div>
</template>

<script>
import { request } from '../../../../network';
import { getMemberId, getVip } from '@/assets/public/utils/token';
import { mapState } from "vuex";
export default {
  name: 'declare',
  data () {
    return {
      title: '申报计划',
      history_title: '历史申报计划',
      list: [],
      str: [
        '项目简称',
        '主管部门',
        '预计发文日期',
        '材料清单',
        '负责人',
        '申报服务商',
        '配套服务商'
      ],
      dialogVisible: false,
      formLabelAlign: {
        id: '',
        responsibleMan: '',
        declareSupplier: '',
        serviceSupplier: ''
      },
    };
  },
  props: {},
  computed:{
    ...mapState({
      vip: (state) => state.login.isVip
    }),
  },
  mounted () {
    if(this.vip === '普通用户')return
    this.getDeclarePlan();
  },
  methods: {
    getDeclarePlan () {
      request({
        method: 'GET',
        url: '/pcp/declaremanager/getDeclarePlan',
        params: {
          memberId: getMemberId()
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.list = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    edit (v) {
      this.dialogVisible = true;
      this.formLabelAlign.id = v.id;
      this.formLabelAlign.responsibleMan = v.responsibleMan;
      this.formLabelAlign.declareSupplier = v.declareSupplier;
      this.formLabelAlign.serviceSupplier = v.serviceSupplier;
      // this.$message('暂未开放此功能')
    },
    closeDialog () {},
    submitForm () {
      var that = this;
      var str = that.formLabelAlign;
      request({
        method: 'POST',
        url: '/pcp/declaremanager/update',
        data: {
          id: str.id,
          declareSupplier: str.declareSupplier,
          serviceSupplier: str.serviceSupplier,
          responsibleMan: str.responsibleMan,
          createDate: '',
          formalId: '',
          memberId: ''
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.dialogVisible = false;
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.getDeclarePlan();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getLastDeclarePlan () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/declaremanager/getLastDeclarePlan',
        params: {
          memberId: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('获取历史申报计划失败');
      this.list = res.data;
    },
    handleHistory () {
      if (this.history_title === '历史申报计划') {
        this.title = '历史申报计划';
        this.history_title = '申报计划';
        this.getLastDeclarePlan();
      } else {
        this.title = '申报计划';
        this.history_title = '历史申报计划';
        this.getDeclarePlan();
      }
    },
    upgrade () {
        this.$message({
          message: '已向客服发送请求成为协会会员通知',
          type: 'success'
        });
    }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.declare {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  min-height: 60vh;
  .subtitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #156ED0;
    margin-bottom: 36px;
    img {
      width: 18px;
      height: 16px;
      margin-right: 5px;
    }
  }
  .timeline {
    margin-bottom: 30px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    .timeline-item {
      margin-top: 30px;
      .month_item {
        position: relative;
        padding-bottom: 20px;
        .item-moth {
          position: absolute;
          left: 0px;
          font-size: 16px;
          font-weight: 400;
        }
        .item-tail {
          position: absolute;
          left: 60px;
          height: 100%;
          border-left: 2px solid #e4e7ed;
        }
        .item-node {
          left: 54px;
          width: 16px;
          height: 16px;
          position: absolute;
          background-color: #156ED0;
          // border: 2px solid #ccc;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .box-card {
        position: relative;
        left: 120px;
        width: 800px;
        font-size: 16px;
        font-weight: 400;
        color: #666;
        .title {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
        div {
          margin-bottom: 20px;
        }
        div:nth-child(2) {
          span {
            margin-right: 40px;
          }
        }
        div:nth-child(4) {
          span {
            margin-right: 60px;
          }
          span:nth-child(4) {
            display: inline-block;
            width: 60px;
            height: 32px;
            border: 1px solid #156ED0;
            border-radius: 5px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #156ED0;
            text-align: center;
            line-height: 32px;
          }
        }
      }
    }
  }
}
.none-data {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 340px;
 }
</style>
