<template>
  <div class="project" style="position: relative;">
    <vipPermission v-if="vip === '普通用户'" :vipPermissionVisible="true" :type="3"></vipPermission>
    <div>
      <div class="tabs">
        <div v-for="(item, index) in tabs" :key="index">
          <div
            :class="item.active ? 'ft_black' : 'ft_gray'"
            @click="handleTabs(index)"
          >
            {{ item.name }}
          </div>
          <div class="line">
            <div v-if="item.active"></div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="screen">
          <el-row class="select">
            <el-col :span="10">
              <span>项目层级：</span>
              <el-select
                v-model="hierarchy_value"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in hierarchy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="10">
              <span>扶持方式：</span>
              <el-select
                v-model="support_value"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in support"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <el-row class="time">
            <el-col class="block" :span="10">
              <span class="demonstration">申报日期：</span>
              <el-date-picker
                v-model="date"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col class="input" :span="10">
              <span>搜索项目：</span>
              <el-input
                v-model="input"
                placeholder="请输入项目名称"
              ></el-input>
            </el-col>
            <el-col :span="2"></el-col>
          </el-row>
        </div>
        <div class="table" v-if="tableData.length>0">
          <el-table :data="tableData" tooltip-effect style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
              width="50"
              fixed
            ></el-table-column>
            <el-table-column prop="subTitle" label="项目简称"></el-table-column>
            <el-table-column prop="hierarchy" label="项目层级"></el-table-column>
            <el-table-column prop="declareTime" label="申报日期">
            </el-table-column>
            <el-table-column prop="supportType" label="扶持方式">
            </el-table-column>
            <el-table-column prop="supportStandard" label="扶持标准">
              <template v-slot="scope">
                <el-tooltip class="item_tooltip" effect="dark" placement="top">
                  <div slot="content">
                    <div
                      v-show="scope.row.supportStandard.indexOf('<br/>') > -1"
                      v-for="item in scope.row.supportStandard.split('<br/>')"
                      :key="item"
                    >
                      {{ item }}
                    </div>
                    <div v-show="scope.row.supportStandard.indexOf('<br/>') < 1">
                      {{ scope.row.supportStandard }}
                    </div>
                  </div>
                  <span>{{ scope.row.supportStandard.slice(0, 5) + "..." }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="joined" fixed="right">
              <template slot-scope="scope">
                <el-button
                  :type="scope.row.joined ? '' : 'primary'"
                  plain
                  @click="handleClick(scope.row)"
                  >{{ scope.row.joined ? "已纳入" : "+纳入计划" }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else class="none-data">
        <img src="@/assets/pc/images/personal/none.png" alt="">
          <span style="font-size: 14px; color: #B0B0B0">暂无内容</span>
        </div>
      </div>
    </div>
    <!-- <open v-else @upgrade="upgrade"></open> -->
  </div>
</template>

<script>
import { request } from '../../../../network';
import { getMemberId, getVip } from '@/assets/public/utils/token';
import { mapState } from "vuex";
export default {
  name: 'project',
  data () {
    return {
      tabs: [
        { id: 0, name: '可申报项目', active: true },
        { id: 0, name: '筹划中项目', active: false }
      ],
      hierarchy: [
        { value: '0', label: '未选分级' },
        { value: '1', label: '中央' },
        { value: '2', label: '省级' },
        { value: '3', label: '市级' },
        { value: '4', label: '区级' },
        { value: '5', label: '镇街级' }
      ],
      hierarchy_value: '',
      support: [],
      support_value: '',
      date: [],
      input: '',
      tableData: [],
      type: '0',
    };
  },
  props: {},
  mounted () {
    this.getSupportType();
    if(this.vip === '普通用户')return
    this.getFormalDesign('', '', '', '', '', '0');
  },
  methods: {
    handleTabs (index) {
      this.tabs.forEach((v, i) =>
        i === index ? (v.active = true) : (v.active = false)
      );
      this.type = index;
    },
    // 获取扶持方式
    getSupportType () {
      request({
        method: 'GET',
        url: '/pcp/declaremanager/getSupportType'
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.support = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 根据条件筛选列表
    getFormalDesign (zcLevel, supportType, startDate, endDate, keyWord, type) {
      const that = this;
      request({
        method: 'GET',
        url: '/pcp/declaremanager/getFormalDesign',
        params: {
          memberId: getMemberId(),
          //  memberId:6321,
          zcLevel: zcLevel,
          supportType: supportType,
          startDate: startDate,
          endDate: endDate,
          keyWord: keyWord,
          type: that.type
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.forEach((v) => {
              let idx = this.hierarchy.findIndex(e=>e.value==v.levle)
              v.hierarchy = this.hierarchy[idx].label;
            });
            that.tableData = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 纳入计划
    handleClick (v) {
      var that = this;
      const str = this.keyWord.split('|');
      request({
        method: 'POST',
        url: '/pcp/declaremanager/addDeclarePlan',
        data: {
          memberId: getMemberId(),
          formalId: v.formalId,
          type: v.joined ? '1' : '0'
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            that.$message({
              message: '纳入计划成功',
              type: 'success'
            });
            that.getFormalDesign(str[0], str[1], str[2], str[3], str[4], '0');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    upgrade () {
      this.$message({
          message: '已向客服发送请求成为协会会员通知',
          type: 'success'
        });
    }
  },
  watch: {
    keyWord (value) {
      const str = value.split('|');
      this.getFormalDesign(str[0], str[1], str[2], str[3], str[4]);
    }
  },
  computed: {
    // 封装筛选条件
    keyWord () {
      const a = '|';
      return (
        this.hierarchy_value +
        a +
        this.support_value +
        a +
        (this.date[0] ? this.date[0] : '') +
        a +
        (this.date[1] ? this.date[1] : '') +
        a +
        this.input +
        a +
        this.type +
        a
      );
    },
    ...mapState({
      vip: (state) => state.login.isVip
    }),
  }
};
</script>

<style lang="less" scoped>
.project {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  height: auto;
  min-height: 60vh;
  .tabs {
    padding-top: 10px;
    display: flex;
    div {
      margin-right: 40px;
      .line {
        margin-top: 9px;
        width: 107px;
        div {
          width: 40px;
          height: 4px;
          background: #156ED0;
          margin-left: 34px;
        }
      }
    }
  }

  .screen {
    margin-top: 32px;
    font-size: 14px;
    opacity: 0.8;
    .select {
      display: flex;
      margin-bottom: 20px;
      .el-select {
        width: 240px;
      }
    }
    .time {
      display: flex;
      .input {
        display: flex;
        align-items: center;
        .el-input {
          width: 240px;
        }
      }
      .block {
        display: flex;
        align-items: center;
        .el-date-editor--daterange.el-input__inner {
          width: 240px;
        }
      }
    }
  }
  .table {
    margin-top: 40px;
  }
}
.ft_gray {
  opacity: 0.6;
}
.ft_black {
  opacity: 1;
}
.el-tooltip__popper {
  max-width: 60% !important;
}
.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  max-width: 25rem !important;
  line-height: 14px;
  color: #fff !important;
  background-color: rgb(48, 65, 86) !important;
}
.none-data {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 340px;
 }
</style>
